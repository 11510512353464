import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';
import SEO from '../components/SEO';

const HomeStyles = styled.div`
  align-items: center;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  text-align: left;
  margin: 0 4rem;
  line-height: 3rem;

  @media (min-width: 800px) {
    margin: 5rem 10rem;
  }
  @media (min-width: 1200px) {
    margin: 5rem 20rem;
  }
  @media (min-width: 1500px) {
    margin: 5rem 30rem;
  }
`;

const HomeLogo = styled.div`
  margin: 0;
  padding
`;

const UlStyles = styled.ul`
  list-style: none;
  font-weight: bold;
  font-size: 1.8rem;

  li {
    margin: 1.5rem 0;
  }
`;

export default function HomePage() {
  return (
    <>
      <SEO title={'Home'} />
      <HomeStyles>
        <HomeLogo>
          <StaticImage
            src="../assets/images/light-logo.png"
            alt="Critters in a house"
            placeholder="Critter Sitter"
            width={500}
            height={250}
          />
        </HomeLogo>
        <p>
          We offer personalized in-home pet sitting, dog socialization
          activities, pet taxi services and visits for 'critters' of all sizes!
        </p>
        <p>
          As lifelong pet lovers, we have spent years volunteering at and
          contributing to local animal shelters, organizations and adoption
          events. We love fostering local rescues, several of which have found
          their forever home with us, so we know that your pet is an important
          part of your family. Our goal is to ensure that your critter receives
          consistent, quality care even when you can’t be there.
        </p>
        <UlStyles>
          <li>
            Unable to bring your critter (snake, fish, dog, pig, bird, tiger)
            along with you during an out of town trip?
          </li>
          <li>
            Want to guarantee that your pet gets daily exercise and
            socialization while you are out at work?
          </li>
          <li>
            Worried about your home looking neglected or unoccupied while you're
            away?
          </li>
        </UlStyles>
        <p>
          <strong>Humboldt Critter Sitter</strong> delivers reliable, efficient
          and personable services for all your pet and home sitting needs.
        </p>
        <p>
          In-home pet sitting allows your animals to feel safer, calmer and more
          at ease while you are away. Our services include one or more daily
          visits with playtime, walks, fresh food and water, cleanup and
          administering any medications. You can rest assured that your small
          (or large) loved ones are happy, healthy and taken care of until you
          return.
        </p>
        <p>
          During our personalized visits, we can keep your house looking 'lived
          in' by bringing in you mail and other packages, putting out garbage
          and recycling, opening/shutting blinds and rotating lights. We can
          even keep your indoor/outdoor plant watering schedule on track!
        </p>
        <p>
          Be secure and comfortable leaving your critter at home with us while
          you are away at play (or work). We offer complimentary personal
          consultations to discuss and meet your loved one.
        </p>
        <p>
          Don't delay, call today. Your critter will love you even more for it!
        </p>
        <button>
          <Link to="/services">Find Out More</Link>
        </button>
      </HomeStyles>
    </>
  );
}
